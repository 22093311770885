import "./sass/main.sass";
import Blazy from "blazy";
import toggleScrollClass from "./js/toggleScrollClass";
import skrollr from "skrollr";

document.addEventListener("DOMContentLoaded", () => {
  var menu = document.getElementById("menu");
  var _document = document,
    body = _document.body;

  if (menu) {
    var toggleClass = function toggleClass(el, elClass) {
      if (el.classList.toString().indexOf(elClass) > -1) {
        el.classList.remove(elClass);
      } else {
        el.classList.add(elClass);
      }
    };

    menu.addEventListener("click", function(evt) {
      toggleClass(body, "menu-open");
    });
  }

  const articleImages = document.querySelectorAll(".post__article img");
  [].forEach.call(articleImages, articleImage =>
    articleImage.classList.add("lazy")
  );
  const lazyLoader = new Blazy({
    selector: ".lazy"
  });

  toggleScrollClass("header");
  if (!("ontouchstart" in window)) {
    skrollr.init();
  }
});
